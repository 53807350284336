var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'wrapper-modal',
    { 'wrapper-modal--success': _vm.screen === 'screen-two' }
  ]},[_c('div',{staticClass:"wrapper-modal__header"},[_c('div',{staticClass:"header__title"},[_vm._v(_vm._s(_vm.$t('importKmlTranslater.title')))]),_c('div',{staticClass:"header__close"},[_c('close-icon',{nativeOn:{"click":function($event){return _vm.closeKml.apply(null, arguments)}}})],1)]),(_vm.screen === 'screen-one')?_c('div',{staticClass:"modal-screen"},[_c('div',{staticClass:"wrapper-modal__body"},[_c('p',[_vm._v(_vm._s(_vm.$t('importKmlTranslater.selectFileKML')))]),_c('div',{staticClass:"body__file"},[_c('div',{staticClass:"select-company"},[_c('label',{staticClass:"select-company-label",attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t('importKmlTranslater.inCompany'))+" ")]),_c('el-select',{staticClass:"adminSelect",attrs:{"filterable":"filterable","multiple":false,"collapse-tags":"collapse-tags"},model:{value:(_vm.selectCompany),callback:function ($$v) {_vm.selectCompany=$$v},expression:"selectCompany"}},_vm._l((_vm.companies),function(item){return _c('el-option',{key:item.id,staticClass:"text-ellipsis",staticStyle:{"max-width":"265px"},attrs:{"value":item.id,"label":item.name || '-'}})}),1)],1),_c('label',{staticClass:"select-description"},[_c('input',{attrs:{"type":"file","accept":".kml"},on:{"change":_vm.showFile}}),_vm._v(" "+_vm._s(_vm.$t('importKmlTranslater.selectFile'))+" ")]),_c('files-icon')],1),(_vm.error)?_c('span',{staticClass:"body__error"},[_vm._v(_vm._s(_vm.error))]):_c('span',{staticClass:"body__fileName"},[_vm._v(_vm._s(_vm.fileName))])]),_c('div',{staticClass:"wrapper-modal__footer"},[_c('skif-button',{class:[
          'primary wialon-btn',
          {
            'wialon-btn--disabled': !this.file || this.error || !_vm.selectCompany
          }
        ],on:{"click":_vm.getImport}},[_vm._v(" "+_vm._s(_vm.$t('importKmlTranslater.import'))+" ")])],1)]):_vm._e(),(_vm.screen === 'screen-two')?_c('div',{staticClass:"modal-screen"},[_c('div',{staticClass:"wrapper-modal__body success-screen"},[_vm._v(" "+_vm._s(_vm.messageText)+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }