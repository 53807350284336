<template>
  <div
    :class="[
      'wrapper-modal',
      { 'wrapper-modal--success': screen === 'screen-two' }
    ]"
  >
    <div class="wrapper-modal__header">
      <div class="header__title">{{ $t('importKmlTranslater.title') }}</div>
      <div class="header__close">
        <close-icon @click.native="closeKml" />
      </div>
    </div>

    <div v-if="screen === 'screen-one'" class="modal-screen">
      <div class="wrapper-modal__body">
        <p>{{ $t('importKmlTranslater.selectFileKML') }}</p>
        <div class="body__file">
          <div class="select-company">
            <label class="select-company-label" for="">
              {{ $t('importKmlTranslater.inCompany') }}
            </label>
            <el-select
              class="adminSelect"
              v-model="selectCompany"
              filterable="filterable"
              :multiple="false"
              collapse-tags="collapse-tags"
            >
              <el-option
                class="text-ellipsis"
                style="max-width: 265px"
                v-for="item in companies"
                :key="item.id"
                :value="item.id"
                :label="item.name || '-'"
              />
            </el-select>
          </div>
          <label class="select-description">
            <input type="file" accept=".kml" @change="showFile" />
            {{ $t('importKmlTranslater.selectFile') }}
          </label>
          <files-icon />
        </div>
        <span v-if="error" class="body__error">{{ error }}</span>
        <span v-else class="body__fileName">{{ fileName }}</span>
      </div>
      <div class="wrapper-modal__footer">
        <skif-button
          :class="[
            'primary wialon-btn',
            {
              'wialon-btn--disabled': !this.file || this.error || !selectCompany
            }
          ]"
          @click="getImport"
        >
          {{ $t('importKmlTranslater.import') }}
        </skif-button>
      </div>
    </div>

    <div v-if="screen === 'screen-two'" class="modal-screen">
      <div class="wrapper-modal__body success-screen">
        {{ messageText }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CloseIcon from '@/components/admin-panel/components/assets/icons/CloseIcon.vue'
import FilesIcon from '@/components/admin-panel/components/assets/icons/FilesIcon.vue'

export default {
  name: 'Import-Geozones-Kml',
  components: {
    CloseIcon,
    FilesIcon
  },
  data() {
    return {
      maxSizeFile: 5242880, // 5mb
      error: '',
      file: null,
      fileName: '',
      screen: 'screen-one',
      messageText: '',
      selectCompany: ''
    }
  },

  computed: {
    ...mapGetters({
      companies: 'login/companies'
    })
  },

  methods: {
    ...mapActions({
      createImport: 'geozonesKml/createImport'
    }),

    async getImport() {
      await this.createImport({
        companyId: this.selectCompany,
        file: this.file
      })
        .then((resp) => {
          this.messageText = resp.data.result
        })
        .catch((err) => {
          console.log(err.message, 'err')
        })
      this.screen = 'screen-two'
    },

    closeKml() {
      this.$emit('close-kml')
    },

    showFile(event) {
      this.error = ''
      this.file = event.target.files[0]
      this.fileName = ''
      if (this.file) {
        this.fileName = this.file.name
        if (this.file.size > this.maxSizeFile) {
          return (this.error = this.$t('importKmlTranslater.import'))
        }
        if (!this.fileName.includes('.kml')) {
          return (this.error = this.$t('importKmlTranslater.formatKML'))
        }
      }
    }
  },

  beforeDestroy() {
    this.file = null
  }
}
</script>

<style lang="stylus" scoped>
.wrapper-modal
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)
  z-index 10
  width 760px
  height 635px
  background-color #fff
  box-shadow 0px 0px 10px rgba(0, 0, 0, 0.1)

  &--success
    height 292px

  &__header
    display flex
    justify-content space-between
    padding 25px
    font-family Source Sans Pro
    font-size 26px
    line-height 32px
    color #5477A9
    font-weight 700

    .header
      &__close
        cursor pointer

  &__body
    p
      padding-bottom 15px
      margin 0 25px
      font-family Lato
      font-weight 500
      font-size 16px
      line-height 24px
      color #677785
      text-align center
      border-bottom 1px solid #E6EAEE

    .body
      &__file
        padding 0 25px 0 25px
        margin-top 68px

        .select-description
          margin-right 13px
          font-family Lato
          font-weight 700
          text-transform uppercase
          font-size 16px
          line-height 16px
          color #5477A9
          cursor pointer

        input[type="file"]
          display none

      &__error
        padding 16px 25px 0 25px
        font-family Source Sans Pro
        font-size 14px
        color #F56C6C
      &__fileName
        padding 16px 25px 0 25px
        font-family Source Sans Pro
        font-size 14px
        color #5477A9

  &__footer
    position absolute
    bottom 25px
    right 25px
    .wialon-btn
      width 220px
      height 45px
      &--disabled
        opacity 0.4
        pointer-events none

.select-company
  margin-bottom 50px
  .select-company-label
    margin-right 127px
    font-weight 600
    font-size 18px
    line-height 19px
    color #3C5E8F

.el-select.admin-select
  width 349px

.success-screen
  margin 50px 0
  text-align center
  font-family Source Sans Pro
  font-size 16px
  font-weight 700
  line-height 27px
  color #5477A9
</style>
